import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import VueStash from "vue-stash";
Vue.use(VueStash);

import isMobile from "@/inc/isMobile.js";

let scrollOnBoardingCount = parseInt(
  localStorage.getItem("scrollOnBoardingCount")
);

if (!scrollOnBoardingCount) {
  scrollOnBoardingCount = 0;
  localStorage.setItem("scrollOnBoardingCount", 0);
}

import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: "G-KJ4FQVFH4E"
});

new Vue({
  router,
  render: h => h(App),
  data: {
    store: {
      filters: {
        styles: [],
        persona: [],
        roles: [],
        builtWith: [],
        selectedFilters: [],
        selectedStyles: [],
        selected_persona: {},
        selected_builtWith: {},
        selected_roles: {}
      },
      app: {
        selectedItem: null,
        selectedItemsLength: 0,
        itemView: false,
        mobileMenuOpen: false,
        isMobile,
        scrollOnBoardingCount
      }
    }
  }
}).$mount("#app");

window.swagSound = new Audio("/swag.m4a");
