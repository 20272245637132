<template>
  <div class="items-container" v-if="selectedItems.length">
    <div class="top-bar">
      <div class="left">
        <span v-if="selectedItems.length == items.length"
          >{{ items.length }} portfolios, adding one portfolio per day.
          <span v-if="!app.isMobile">Closed on Sundays.</span>
        </span>
        <span v-else-if="selectedItems.length == 0"> </span>
        <span v-else> {{ selectedItems.length }} portfolios found. </span>
      </div>
      <div class="right" v-if="selectedItems.length">
        <span
          class="tag"
          @click="filterClick(item)"
          v-for="item in filters.selectedFilters"
          :key="item.id"
          >● {{ item.name }}</span
        >
      </div>
    </div>
    <div class="no-results" v-if="selectedItems.length == 0">
      <Bottom from="from-items" />
      <a class="button selected" href="javascript:;" @click="resetAllFilters()">
        Click to reset all filters
      </a>
      <Bottom from="from-items" direction="direction-left" />
    </div>
    <!-- /.no-results -->
    <div class="items" v-show="!app.mobileMenuOpen">
      <Item
        v-for="item in selectedPageItems.slice(0, itemsPerPage / 2)"
        :key="item.id"
        :item="item"
      />
      <Tools />
      <Item
        v-for="item in selectedPageItems.slice(itemsPerPage / 2, itemsPerPage)"
        :key="item.id"
        :item="item"
      />
    </div>
    <!-- /.items -->
    <div class="pagination" v-show="selectedItems.length != 0">
      <paginate
        :page-count="pageCount"
        :click-handler="paginationClick"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination-container'"
        :model="currentPage"
        :page-class="'page-class'"
        :next-class="'next-class'"
        :prev-class="'prev-class'"
        ref="paginate"
      >
      </paginate>
    </div>
    <!-- /.pagination -->
  </div>
  <!-- /.items-container -->
</template>

<script>
import Item from "@/components/Home/Item.vue";
import Tools from "@/components/Home/Tools.vue";
import Bottom from "@/components/Header/Bottom.vue";
import { getItems } from "@/data";

export default {
  name: "Items",
  components: { Item, Tools, Bottom },
  store: ["filters", "app"],
  computed: {
    pageCount() {
      return Math.ceil(this.app.selectedItemsLength / this.itemsPerPage);
    },
    selectedPageItems() {
      return this.selectedItems.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
    },
    selectedItems() {
      if (this.filters.selectedFilters.length == 0) return this.items;
      return this.items.filter(item => {
        const hasStyles = this.filters.selectedStyles.every(ss =>
          item.styles.includes(ss.id)
        );
        const boolPersona = this.filters.selected_persona.id
          ? this.filters.selected_persona.id == item.persona
          : true;
        const boolBuiltWith = this.filters.selected_builtWith.id
          ? this.filters.selected_builtWith.id == item.builtWith
          : true;
        const hasRoles = this.filters.selected_roles.id
          ? item.roles.includes(this.filters.selected_roles.id)
          : true;
        return hasStyles && boolPersona && boolBuiltWith && hasRoles;
      });
    }
  },
  watch: {
    selectedItems(newVal) {
      if (!newVal.length) return;

      this.currentPage = 1;
      this.app.selectedItemsLength = newVal.length;
    }
  },
  data() {
    return {
      items: [],
      itemsPerPage: 4,
      currentPage: 1,
      multiple: [
        {
          keyCode: 37,
          preventDefault: true
        },
        {
          keyCode: 39,
          preventDefault: true
        }
      ]
    };
  },
  async beforeMount() {
    window.addEventListener("keyup", this.handleKeypress);
    this.items = await getItems();
    this.app.selectedItem = this.items[0];
    // console.log("items", this.items);
    // console.log("styles", this.filters.styles);
    this.app.selectedItemsLength = this.items.length;
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeypress);
  },
  methods: {
    filterClick(item) {
      item.selected = false;
      if (
        this.filters.selected_builtWith &&
        this.filters.selected_builtWith.id == item.id
      ) {
        this.filters.selected_builtWith = {};
      }
      if (
        this.filters.selected_persona &&
        this.filters.selected_persona.id == item.id
      ) {
        this.filters.selected_persona = {};
      }
      if (
        this.filters.selected_roles &&
        this.filters.selected_roles.id == item.id
      ) {
        this.filters.selected_roles = {};
      }
      this.filters.selectedFilters = this.filters.selectedFilters.filter(
        obj => {
          return obj.id != item.id;
        }
      );
    },
    resetAllFilters() {
      this.filters.selected_roles = {};
      this.filters.selected_persona = {};
      this.filters.selected_builtWith = {};
      this.filters.selectedStyles = [];
      this.filters.selectedFilters = [];
      this.filters.builtWith.forEach(element => {
        element.selected = false;
      });
      this.filters.persona.forEach(element => {
        element.selected = false;
      });
      this.filters.roles.forEach(element => {
        element.selected = false;
      });
      this.filters.styles.forEach(element => {
        element.selected = false;
      });
    },
    paginationClick(pageNum) {
      setTimeout(() => {
        this.currentPage = pageNum;
      }, 250);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    handleKeypress(e) {
      if (e.which == 39) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let el = this.$refs.paginate.$el.querySelector(".next-class a");
        if (el) el.click();
      } else if (e.which == 37) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let el = this.$refs.paginate.$el.querySelector(".prev-class a");
        if (el) el.click();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";

.items-container {
  display: block;
  margin-top: $height-header + $height-page-margin-top + 24px;
  float: left;
  @include padding-width();
  .top-bar {
    font-size: 10px;
    width: 100%;
    float: left;
    top: 0px;
    position: relative;
    height: 20px;
    background: #fff;
    @include f_medium();
    .left {
      top: 3px;
      position: absolute;
    }
    .right {
      float: right;
      .tag {
        margin-left: 7px;
        background: $blue;
        font-size: 10px;
        border-radius: 2px;
        padding: 2px 4px;
        margin-top: 2px;
        margin-bottom: 5px;
        float: left;
        display: inline-block;
        @include click();
        color: #fff;
        @include no-select();
      }
    }
    span {
      @include f_italic();
    }
    a {
      float: right;
      display: inline-block;
      margin-left: 10px;
      color: #a8a8a8;
      &:hover {
        text-decoration: underline;
      }
      &.selected {
        color: #000;
      }
    }
  }
  .no-results {
    text-align: center;
    background: $blue;
    .button {
      @include button();
      border: 1px solid $blue;
      margin-top: 40px;
      margin-bottom: 40px;
      color: #000 !important;
    }
  }
  .items {
    margin-top: 25px;
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    clear: both;
    justify-content: space-between;
  }
  .pagination {
    width: 100%;
    clear: both;
    float: left;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: $mobile-max) {
  .items-container {
    display: block;
    width: 100%;
    padding: 0px;
    .top-bar {
      padding: 0px 15px;
      box-sizing: border-box;
    }
    .items {
      padding: 0px 15px;
      box-sizing: border-box;
      margin-top: 0px;
    }
  }
}
</style>
<style lang="scss">
@import "~@/inc/pagination.scss";
</style>
