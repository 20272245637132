<template>
  <div class="header-container">
    <div class="content">
      <router-link class="logo" to="/">
        <img src="@/assets/pfolios-net-logo-type.svg" alt="" />
      </router-link>
      <a
        class="swag"
        :class="{ animate: swagAnimation }"
        href="javascript:;"
        @click="play()"
      >
        <img src="@/assets/swag.svg" alt="" />
      </a>
      <div class="nav">
        <a class="categories" href="javascript:;">
          <span>Categories</span>
          <Categories class="categories-container" />
        </a>
        <router-link to="/submit">Submit</router-link>
        <router-link to="/about">About</router-link>
      </div>
      <!-- /.nav -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.header-container -->
</template>

<script>
import Categories from "@/components/Header/Categories.vue";

export default {
  name: "Header",
  components: {
    Categories
  },
  store: ["app"],
  data() {
    return {
      swagAnimation: true
    };
  },
  methods: {
    play: function() {
      window.swagSound.play();
      this.swagAnimation = false;
      setTimeout(() => {
        this.swagAnimation = true;
      }, 150);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.header-container {
  display: block;
  position: absolute;
  height: $height-header;
  background: $bg-gray;
  width: 100vw;
  top: $height-page-margin-top;
  z-index: 100;
  * {
    @include no-select();
  }
  .content {
    @include padding-width();
    margin: 0 auto;
    .logo {
      float: left;
      @include click();
      margin-top: ($height-header - 16px) /2;
      img {
        width: 126px;
        height: 16px;
      }
    }
    .swag {
      float: left;
      margin-left: 6px;
      margin-top: ($height-header - 16px) / 2 - 1;
      position: relative;
      @include click();
      img {
        width: 49px;
        height: 12px;
      }
      &.animate {
        animation: dealwit 2s forwards;
      }
    }
  }
  .nav {
    float: right;
    height: $height-header;
    line-height: $height-header;
    color: $blue;
    font-size: 12px;
    @include f_medium();

    a {
      margin-left: 40px;
      display: inline-block;
      position: relative;
      &:not(.categories) {
        @include click();
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .categories {
      &::after {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 0;
        width: 0;
        border: 8px solid transparent;
        z-index: 2;
        border-bottom-color: $green;
      }
      &:hover {
        &:after {
          content: "";
        }
        .categories-container {
          visibility: visible;
        }
      }
    }
    .categories-container {
      width: 710px;
      background: #000;
      //   height: 450px;
      position: absolute;
      left: -450px;
      box-shadow: 1px 2px 11px 0 rgba(89, 89, 89, 0.5);
      visibility: hidden;
      //   display: block;
      color: #fff;
      box-sizing: border-box;
      padding: 0px 10px;
    }
  }
}
@media (max-width: $mobile-max) {
  .header-container {
    display: block;
    padding: 0px 15px;
    box-sizing: border-box;
    .content {
      padding: 0px;
      width: 100%;
    }
    .nav {
      display: none !important;
    }
  }
}

@keyframes dealwit {
  from {
    transform: translateY(-250px);
  }
  to {
    transform: translateY(0px);
  }
}
</style>
