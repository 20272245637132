<template>
  <div class="filters-container">
    <label>Active Filters:</label>
    <div class="filters">
      <span
        @click="filterClick(item)"
        v-for="item in filters.selectedFilters"
        :key="item.id"
      >
        ● {{ item.name }}
      </span>
    </div>
  </div>
  <!-- /.filters-container -->
</template>

<script>
export default {
  name: "Filters",
  components: {},
  store: ["filters"],
  data() {
    return {};
  },
  methods: {
    filterClick(item) {
      item.selected = false;
      if (
        this.filters.selected_builtWith &&
        this.filters.selected_builtWith.id == item.id
      ) {
        this.filters.selected_builtWith = {};
      }
      if (
        this.filters.selected_persona &&
        this.filters.selected_persona.id == item.id
      ) {
        this.filters.selected_persona = {};
      }
      if (
        this.filters.selected_roles &&
        this.filters.selected_roles.id == item.id
      ) {
        this.filters.selected_roles = {};
      }
      this.filters.selectedFilters = this.filters.selectedFilters.filter(
        obj => {
          return obj.id != item.id;
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.filters-container {
  display: block;
  color: #fff;
  //   background: red;
  width: 100%;
  max-height: 54px;
  height: 54px;
  //   float: left;
  line-height: 16px;
  bottom: 2px;
  left: 0px;
  position: absolute;
  overflow: hidden;
  label {
    float: left;
    display: inline-block;
    height: 16px;
    margin-left: 43px;
    font-size: 11px;
    position: relative;
    top: 4px;
  }
  .filters {
    width: calc(100% - 160px);
    // background: greenyellow;
    float: right;
    height: auto;
  }
  span {
    margin-left: 7px;
    background: $blue;
    font-size: 10px;
    border-radius: 2px;
    padding: 2px 4px;
    margin-top: 2px;
    margin-bottom: 5px;
    float: left;
    display: inline-block;
    @include click();
  }
}
@media (max-width: $mobile-max) {
  .filters-container {
    display: block;
  }
}
</style>
