<template>
  <div class="home-container">
    <BlueBar />
    <Items />
    <Footer />
  </div>
  <!-- /.home-container -->
</template>

<script>
import BlueBar from "@/components/BlueBar/BlueBar.vue";
import Items from "@/components/Home/Items.vue";
import Footer from "@/components/Home/Footer.vue";

export default {
  name: "Home",
  components: {
    BlueBar,
    Items,
    Footer
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.home-container {
  display: block;
  width: $width-site;
  margin: 0 auto;
  position: relative;
}
@media (max-width: $mobile-max) {
  .home-container {
    display: block;
    width: 100%;
  }
}
</style>
