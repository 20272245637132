<template>
  <div class="bottom-container" :class="[from, direction]">
    <div class="slider">
      <span v-for="(n, index) in 6" :key="index">
        <animated-number
          class="num"
          :class="{ animate: animateShake }"
          :round="true"
          :value="app.selectedItemsLength"
          :duration="200"
          v-if="app.selectedItemsLength"
        />
        <span v-else>NO </span>
        PORTFOLIOS FOUND ●
      </span>
    </div>
  </div>
  <!-- /.bottom-container -->
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
  name: "Bottom",
  components: {
    AnimatedNumber
  },
  store: ["app"],
  props: ["from", "direction"],
  data() {
    return {
      animateShake: false
    };
  },
  watch: {
    "app.selectedItemsLength": function() {
      this.animateShake = true;
      setTimeout(() => {
        this.animateShake = false;
      }, 300);
    }
  },
  mounted() {
    // setInterval(() => {
    //   this.totalResults += 10;
    // }, 3000);
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.bottom-container {
  height: 33px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #000;
  line-height: 33px;
  background: $green;
  @include f_medium();
  font-weight: bold;
  letter-spacing: 2px;
  overflow: hidden;
  display: block;
  font-size: 12px;
  &.from-items {
    float: left;
    position: inherit;
    .slider {
      width: 200%;
    }
  }
  .slider {
    width: 300%;
    animation: slide-left 35s linear infinite;
    .num {
      background: #000;
      color: $green;
      padding: 3px 3px 3px 5px;
      height: 12px;
      line-height: 1;
      text-align: center;
      color: #fff;
      display: inline-block;
      &.animate {
        animation: ring 2s ease infinite;
      }
    }
  }
  &.direction-left {
    .slider {
      animation: slide-right 35s linear infinite;
    }
  }
}
@media (max-width: $mobile-max) {
  .bottom-container {
    display: block;
  }
}
@keyframes slide-left {
  from {
    transform: translateX(-3%);
  }

  to {
    transform: translateX(-26%);
  }
}
@keyframes slide-right {
  from {
    transform: translateX(-26%);
  }

  to {
    transform: translateX(-3%);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>
