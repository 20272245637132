<template>
  <div class="menu-container">
    <router-link to="/about">About</router-link>
    <br />
    <router-link to="/submit">Submit</router-link>
    <br />
    <router-link to="/subscribe">Subscribe</router-link>
  </div>
  <!-- /.menu-container -->
</template>

<script>
export default {
  name: "menu",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.menu-container {
  display: block;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  a {
    font-size: 20px;
    margin-bottom: 30px;
    display: block;
    float: left;
    width: 100%;
    &:first-child {
      margin-top: 100px;
    }
  }
}
@media (max-width: $mobile-max) {
  .menu-container {
    display: block;
  }
}
</style>
