<template>
  <a
    :href="item.url + '?ref=pfolios.net&💞Love-is-in-the-air...💞'"
    target="_blank"
    @click="select(item, $event)"
    class="item-container"
    :class="item.builtWith"
  >
    <div class="colors" @click.prevent>
      <div class="color"></div>
      <div class="color"></div>
      <div class="color"></div>
    </div>
    <div class="preview">
      <img :src="imgSrc" alt="" />
    </div>
    <a href="javascript:;" class="name">{{ item.name }} <span>→</span></a>
  </a>
  <!-- /.item-container -->
</template>

<script>
import { getImageSource } from "@/data";

export default {
  name: "Item",
  components: {},
  props: ["item"],
  store: ["app"],
  computed: {
    imgSrc: function() {
      return getImageSource(this.item.slug, "web", "560x840");
    }
  },
  data() {
    return {};
  },
  methods: {
    select(item, e) {
      if (!this.app.isMobile) {
        e.preventDefault();
        e.stopPropagation();
        this.app.itemView = true;
        this.app.selectedItem = item;
        console.log(this.$route);
        if (this.$route.name != "Home" || this.$route.name != "Single")
          this.$router.push("Home");
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
@import "~@/inc/tooltip.scss";
.item-container {
  display: block;
  width: 300px;
  background: $bg-gray;
  margin-bottom: 40px;
  align-self: flex-start;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10;
  cursor: pointer;
  &.squarespace2 {
    background-image: linear-gradient(129deg, #000000 0%, #8d8d8d 100%);
    .name {
      color: #fff;
    }
    .built-with-squarespace {
      position: relative;
      display: block;
      float: left;
      margin-right: 10px;
      img {
        width: 28px;
        height: 28px;
        float: right;
        border-radius: 28px;
        position: relative;
        top: 4px;
      }
    }
  }
  &:hover {
    .name span {
      opacity: 1;
      text-decoration: none;
    }
    .name {
      text-decoration: underline;
    }
  }
  .colors {
    position: relative;
    .color {
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: #f65d54;
      float: left;
      @include click();
      &:active {
        transform: scale(0.6) perspective(1px);
      }
      &:nth-child(2) {
        background: #f6ba2f;
        margin-left: 3px;
      }
      &:nth-child(3) {
        background: #27c240;
        margin-left: 3px;
      }
    }
  }
  .preview {
    width: 100%;
    margin-top: 10px;
    // height: 420px;
    overflow: hidden;
    float: left;
    @include click();
    border-radius: 6px;
    &:active {
      transform: scale(0.99) perspective(1px);
    }
    img {
      width: 100%;
      border-radius: 6px;
    }
  }
  .name {
    @include f_medium();
    color: $blue;
    font-size: 11px;
    // background: red;
    height: 30px;
    line-height: 36px;
    display: inline-block;
    float: left;
    text-indent: 1px;
    @include click();
    transform-origin: center center;
    span {
      opacity: 0;
    }
  }
}
@media (max-width: $mobile-max) {
  .item-container {
    display: block;
    margin: 20px auto;
  }
}
@media (max-width: $mobile-max / 2) {
  .item-container {
    // width: 100%;
  }
}
</style>
