<template>
  <transition name="fade">
    <div class="single-container" v-if="app.selectedItem">
      <h2>{{ app.selectedItem.name }}</h2>
      <div class="title">{{ app.selectedItem.title }}</div>
      <div class="buttons">
        <a
          href="javascript:;"
          :class="device == 'web' ? 'selected' : ''"
          @mouseenter="
            device = 'web';
            scrollTop();
          "
        >
          Desktop
        </a>
        <a
          href="javascript:;"
          :class="device == 'mobile' ? 'selected' : ''"
          @mouseenter="
            device = 'mobile';
            scrollTop();
          "
          >Mobile</a
        >
      </div>
      <PerfectScrollbar
        ref="psPreview"
        class="preview"
        :options="{ wheelPropagation: false }"
      >
        <vue-load-image>
          <div
            slot="image"
            :style="`background-image: url(${imgSrc})`"
            :data-src="imgSrc"
          />
          <div class="preloader" slot="preloader">
            <img src="@/assets/loading.gif" />
          </div>
          <div slot="error">error message</div>
        </vue-load-image>

        <img :src="imgSrc" alt="" />
      </PerfectScrollbar>
      <a
        :href="
          app.selectedItem.url + '?ref=pfolios.net&💞Love-is-in-the-air...💞'
        "
        target="_blank"
        class="url"
        >https://{{ app.selectedItem.slug }} <span>→</span></a
      >
      <p></p>
      <div class="details">
        <div class="detail">
          <span class="label">Built with: </span> <i>{{ builthWithName }}</i>
        </div>
        <div class="detail">
          <span class="label">Styles: </span>
          <i v-for="(style, index) in itemStyles" :key="style.id">
            {{ style.name }}<span v-if="index != itemStyles.length - 1">,</span
            ><span v-else>.</span>
          </i>
        </div>
        <div
          class="detail"
          v-if="
            app.selectedItem.twitter ||
              app.selectedItem.instagram ||
              app.selectedItem.linkedin
          "
        >
          <span class="label">Follow on: </span>
          <a
            v-if="app.selectedItem.twitter"
            :href="'https://twitter.com/' + app.selectedItem.twitter"
            target="_blank"
            >Twitter</a
          ><span v-if="app.selectedItem.linkedin || app.selectedItem.instagram"
            >,
          </span>
          <a
            v-if="app.selectedItem.instagram"
            :href="'https://instagram.com/' + app.selectedItem.instagram"
            target="_blank"
            >Instagram</a
          ><span v-if="app.selectedItem.linkedin">, </span>
          <a
            v-if="app.selectedItem.linkedin"
            :href="'https://linkedin.com/in/' + app.selectedItem.linkedin"
            target="_blank"
            >LinkedIn</a
          >
        </div>
      </div>
    </div>
    <!-- /.single-container -->
  </transition>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import VueLoadImage from "vue-load-image";
import { getImageSource } from "@/data";

export default {
  name: "Single",
  components: {
    PerfectScrollbar,
    VueLoadImage
  },
  store: ["app", "filters"],
  computed: {
    imgSrc: function() {
      return getImageSource(this.app.selectedItem.slug, this.device, "560");
    },
    itemStyles() {
      return this.filters.styles.filter(f =>
        this.app.selectedItem.styles.includes(f.id)
      );
    },
    builthWithName() {
      return this.filters.builtWith.find(
        f => f.id == this.app.selectedItem.builtWith
      ).name;
    }
  },
  watch: {
    "app.selectedItem": function() {
      this.device = "web";
      this.miniScroll();
    }
  },
  data() {
    return {
      id: "gianlucarmenio.com",
      device: "web",
      scrolling: false
    };
  },
  mounted() {
    this.scrolling = true;
    setTimeout(() => {
      this.scrollTo(this.$refs.psPreview.$el, 300, 1250);
      setTimeout(() => {
        this.scrollTo(this.$refs.psPreview.$el, 0, 1250);
        this.scrolling = false;
      }, 1250);
    }, 2000);
  },
  methods: {
    miniScroll() {
      if (this.app.scrollOnBoardingCount > 3) {
        this.scrollTop();
        return;
      }
      if (this.scrolling) return;
      this.scrolling = true;
      this.scrollTo(
        this.$refs.psPreview.$el,
        Math.floor(Math.random() * (150 - 50)) + 50,
        500
      );
      setTimeout(() => {
        this.scrollTo(this.$refs.psPreview.$el, 0, 500);
        this.scrolling = false;
      }, 550);
      this.app.scrollOnBoardingCount = this.app.scrollOnBoardingCount + 1;
      localStorage.setItem(
        "scrollOnBoardingCount",
        this.app.scrollOnBoardingCount
      );
    },
    scrollTop() {
      setTimeout(() => {
        this.$refs.psPreview.$el.scrollTop = 0;
      }, 100);
    },
    scrollTo(element, to, duration) {
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      var animateScroll = function() {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    }
  }
};
Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.single-container {
  display: block;
  box-sizing: border-box;
  float: left;
  //   height: calc(100vh - 200px);
  min-height: 400px;
  h2 {
    margin-top: $height-page-margin-top;
  }
  .title {
    font-size: 12px;
    width: 100%;
    float: left;
    margin-top: 12px;
  }
  .buttons {
    width: 100%;
    height: 34px;
    float: left;
    margin-top: 22px;
    a {
      @include button();
      &:hover {
        text-decoration: none;
      }
    }
  }
  .preview {
    float: left;
    width: 100%;
    margin-top: 25px;
    overflow: scroll;
    border-radius: 6px;
    max-height: 400px;
    .preloader {
      position: absolute;
      z-index: 1000;
      background: rgba(255, 255, 255, 0.95);
      height: 100%;
    }
    img {
      width: 100%;
      border-radius: 6px;
    }
  }
  .url {
    display: inline-block;
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 12px;
    span {
      display: inline-block;
    }
    &:hover {
      text-decoration: underline;
      font-style: italic;
      span {
        transform: rotate(-45deg);
      }
    }
  }
  p {
    float: left;
    width: 100%;
    font-size: 11px;
    line-height: 22px;
    margin-top: 16px;
    @include f_regular();
  }
  .details {
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 15px;
    .detail {
      @include f_regular();
      margin-bottom: 10px;
      line-height: 20px;
      .label {
        @include f_medium();
      }
      a {
        text-decoration: underline;
        @include a_folios();
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .single-container {
    display: block;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
