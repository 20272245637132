import Vue from "vue";
import VueRouter from "vue-router";
// import ComingSoon from "@/views/ComingSoon.vue";
import Single from "@/views/Single.vue";
import Submit from "@/views/Submit.vue";
import Subscribe from "@/views/Subscribe.vue";
import Menu from "@/views/Menu.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Single
  },
  {
    path: "/home",
    name: "Single",
    component: Single
  },
  {
    path: "/submit",
    name: "Submit",
    component: Submit
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
