let data;
let dataRes;

const checkData = async () => {
  if (data) return;

  if (dataRes) {
    await dataRes;
    return;
  }

  const pfoliosFileName =
    process.env.NODE_ENV === "stage" ? "pfolios-all" : "pfolios";
  const pfoliosUrl = `https://cdn.pfolios.net/${pfoliosFileName}.json`;

  dataRes = (await window.fetch(pfoliosUrl)).json();
  data = await dataRes;
  dataRes = null;
};

export const getItems = async () => {
  await checkData();

  return data.items;
};

export const getFilters = async () => {
  await checkData();

  const filterKeys = ["styles", "roles", "persona", "builtWith"];

  return filterKeys.reduce((filters, filterKey) => {
    let filterItems = data[filterKey];

    if (filterKey === "builtWith") {
      filterItems = filterItems.sort((a, b) => {
        if (a.id === "squarespace") return -1;

        return a.name < b.name ? -1 : 1;
      });
    }

    filters[filterKey] = filterItems.map(filterItem => ({
      ...filterItem,
      selected: false
    }));

    return filters;
  }, {});
};

export const getImageSource = (slug, device, resolution) => {
  return `https://cdn.pfolios.net/portfolios/${slug}/${slug}.${device}.${resolution}.jpg`;
};
