<template>
  <div
    class="blue-bar-container"
    ref="container"
    v-if="!app.isMobile || (app.isMobile && app.mobileMenuOpen)"
  >
    <div class="router-view">
      <router-view />
    </div>
    <BlueBottom v-if="!app.isMobile" />
  </div>
  <!-- /.blue-bar-container -->
</template>

<script>
import BlueBottom from "@/components/BlueBar/BlueBottom.vue";

export default {
  name: "BlueBar",
  components: { BlueBottom },
  store: ["app"],
  data() {
    return {
      lastScroll: 0
    };
  },
  watch: {
    "app.itemView": function(newVal) {
      if (newVal == true) {
        if (document.documentElement.scrollTop != 0)
          this.$refs.container.style.top =
            document.documentElement.scrollTop + "px";
        if (document.body.scrollTop != 0)
          this.$refs.container.style.top = document.body.scrollTop + "px";
        window.addEventListener("scroll", this.handleScroll);
      }
    }
  },
  methods: {
    handleScroll() {
      let scrollTop = document.body.scrollTop;
      if (document.body.scrollTop == 0)
        scrollTop = document.documentElement.scrollTop;
      if (scrollTop + 20 < this.lastScroll) {
        this.app.itemView = false;
        this.$refs.container.style.top = 0;
        this.lastScroll = 0;
        setTimeout(() => {
          window.removeEventListener("scroll", this.handleScroll);
        }, 500);
      }
      this.lastScroll = scrollTop;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.blue-bar-container {
  display: block;
  width: $width-blue-bar;
  height: auto;
  position: absolute;
  right: 0px;
  top: 0px;
  box-sizing: border-box;
  z-index: 200;
  color: #fff;
  @include f_medium();
  transition: all 0.6s;
  .router-view {
    display: block;
    width: 100%;
    float: left;
    background: $blue;
    border-bottom: 23px solid #000;
    padding: 0px 31px;
    padding-bottom: 30px;
    box-sizing: border-box;
    &::v-deep h2 {
      font-size: 16px;
      width: 100%;
      float: left;
    }
  }
  //   border-right: 1px solid #000;
  //   border-left: 1px solid #000;
}
@media (max-width: $mobile-max) {
  .blue-bar-container {
    display: block;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    position: fixed;
    background: $blue;
  }
}
</style>
