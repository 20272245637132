<template>
  <div class="categories-container">
    <Bottom />
    <div class="menu-section" style="margin-left: 3px; width: 20%;">
      <header>BUILT WITH</header>
      <ul>
        <li v-for="item in filters.builtWith" :key="item.id">
          <a
            href="javascript:;"
            class="icon"
            :class="[
              { selected: filters.selected_builtWith.id == item.id },
              item.id
            ]"
            @click="selectItem(item, 'builtWith')"
            ><span>{{ item.name }}</span></a
          >
        </li>
      </ul>
    </div>
    <div class="menu-section" style="width: 18%">
      <header>PERSONA</header>
      <ul>
        <li v-for="item in filters.persona" :key="item.id">
          <a
            href="javascript:;"
            :class="{ selected: filters.selected_persona.id == item.id }"
            @click="selectItem(item, 'persona')"
            ><span>{{ item.name }}</span></a
          >
        </li>
      </ul>
    </div>
    <div class="menu-section" style="width: 28%">
      <header>STYLES</header>
      <perfect-scrollbar ref="psStyles" :options="{ wheelPropagation: false }">
        <ul>
          <li v-for="item in filters.styles" :key="item.id">
            <a
              href="javascript:;"
              :class="{ selected: item.selected }"
              @click="item.selected = !item.selected"
              ><span>{{ item.name }}</span></a
            >
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
    <div class="menu-section" style="width: 33%">
      <header>ROLE</header>
      <perfect-scrollbar ref="psRoles" :options="{ wheelPropagation: false }">
        <ul>
          <li v-for="item in filters.roles" :key="item.id">
            <a
              href="javascript:;"
              :class="{ selected: filters.selected_roles.id == item.id }"
              @click="selectItem(item, 'roles')"
              ><span>{{ item.name }}</span></a
            >
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
    <Filters v-if="filters.selectedFilters.length" />
  </div>
  <!-- /.categories-container -->
</template>

<script>
import _ from "lodash";
import Bottom from "@/components/Header/Bottom.vue";
import Filters from "@/components/Header/Filters.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "Categories",
  components: {
    Bottom,
    Filters,
    PerfectScrollbar
  },
  store: ["filters", "app"],
  computed: {
    selectedFiltersLocal: function() {
      return this.filters.selectedStyles.concat(
        _.filter(this.filters.roles, { selected: true }).concat(
          _.filter(this.filters.builtWith, { selected: true }).concat(
            _.filter(this.filters.persona, { selected: true })
          )
        )
      );
    }
  },
  watch: {
    "filters.styles": {
      handler() {
        this.filters.selectedStyles = _.filter(this.filters.styles, {
          selected: true
        });
      },
      deep: true
    },
    selectedFiltersLocal(val) {
      this.filters.selectedFilters = val;
      this.portfoliosFoundLength = this.app.selectedItemsLength;
    }
  },
  data() {
    return {
      portfoliosFoundLength: 0
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.psStyles.$el.scrollTop = 1;
      this.$refs.psRoles.$el.scrollTop = 1;
    }, 100);
  },
  methods: {
    selectItem(item, category) {
      item.selected = !item.selected;
      let selected = item.selected;
      this.filters[category].forEach(p => {
        p.selected = false;
      });
      item.selected = selected;
      if (item.selected) this.filters["selected_" + category] = item;
      else this.filters["selected_" + category] = {};
    }
  }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.categories-container {
  display: block;
  transition: 0.2s max-height;
  .ps {
    height: 350px;
  }
  .menu-section {
    width: 25%;
    height: 450px;
    float: left;
    box-sizing: border-box;
    padding: 0px 0px 0px 30px;
    box-sizing: border-box;
    // background: red;
    margin-top: 33px;
    margin-bottom: 13px;
    header {
      font-weight: bold;
      letter-spacing: 0.31px;
      margin-top: 7px;
    }
    li {
      width: 100%;
      float: left;
      height: 25px;
      line-height: 25px;
      width: 100%;
      @include f_regular();
      ul {
        margin-top: 12px;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
        display: inline-block;
        height: 25px;
        width: 100%;
        position: relative;
        span {
          display: flex;
          position: relative;
          white-space: nowrap;
          l &:active {
            top: 1px;
          }
        }
        &.selected {
          span {
            &:after {
              content: " ";
              background: url("~@/assets/check.svg");
              width: 20px;
              height: 20px;
              background-repeat: no-repeat;
              margin-left: 7px;
              top: 2px;
              position: relative;
              display: flex;
            }
          }
        }
        &.icon {
          &:before {
            width: 18px;
            height: 18px;
            /* background: red; */
            border-radius: 100%;
            position: absolute;
            left: -26px;
            top: 2px;
            content: "";
          }
          &.semplice {
            &:before {
              /* background: url("~@/assets/semplice-logo.png"); */
              background-size: 18px 18px;
            }
          }
          &.carbonmade {
            &:before {
              /* background-image: url("~@/assets/carbonmade-logo.png"); */
              background-size: 18px 18px;
            }
          }
          &.webflow {
            &:before {
              /* background-image: url("~@/assets/webflow-logo.png"); */
              background-size: 18px 18px;
            }
          }
          &.squarespace {
            &:before {
              /* background-image: url("~@/assets/squarespace-logo.png"); */
              background-size: 18px 18px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .categories-container {
    display: block;
  }
}
</style>

<style>
.ps__rail-x,
.ps__rail-y {
  opacity: 0.6 !important;
  z-index: 2;
}
</style>
