<template>
  <div class="app-container" v-if="isLoaded">
    <NetworkSidebar />
    <Header />
    <Home />
    <a
      href="javascript:;"
      @click="app.mobileMenuOpen = !app.mobileMenuOpen"
      v-if="app.isMobile"
      class="mobile-menu"
      :class="{ mobileMenuOpen: app.mobileMenuOpen }"
      ><span></span
    ></a>
  </div>
  <!-- /.app-container -->
</template>

<script>
import NetworkSidebar from "@/components/NetworkSidebar.vue";
import Header from "@/components/Header.vue";
import Home from "@/components/Home.vue";
import { getFilters } from "@/data";

export default {
  name: "App",
  components: {
    Header,
    Home,
    NetworkSidebar
  },
  store: ["app", "filters"],
  data() {
    return {
      isLoaded: false
    };
  },
  watch: {
    "app.mobileMenuOpen": function(newVal) {
      if (newVal) {
        this.$router.push("Menu");
      } else {
        this.$router.push("Home");
      }
    }
  },
  async mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1000) {
        // this.app.isMobile = true;
      } else {
        // this.app.isMobile = false;
      }
    });

    const filters = await getFilters();
    this.filters = { ...this.filters, ...filters };
    this.isLoaded = true;

    this.$ga.page("/");
  },
  methods: {}
};
</script>
<style lang="scss">
@import "~@/inc/reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,500;1,400&display=swap");
@import "~@/inc/common.scss";
.app-container {
  display: block;
  font-family: "Roboto Mono";
  @include f_regular();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  position: relative;
}
@media (max-width: $mobile-max) {
  .app-container {
    display: block;
  }
}
.mobile-menu {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 6px;
  margin-right: 15px;
  z-index: 5000;
  right: 0px;
  top: 26px;
}
.mobile-menu span {
  margin: 0 auto;
  position: relative;
  top: 12px;
}
.mobile-menu span:before,
.mobile-menu span:after {
  position: absolute;
  content: "";
}
.mobile-menu span,
.mobile-menu span:before,
.mobile-menu span:after {
  width: 30px;
  height: 6px;
  background-color: $blue;
  display: block;
}
.mobile-menu span:before {
  margin-top: -8px;
}
.mobile-menu span:after {
  margin-top: 8px;
}
.mobile-menu span {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.mobile-menu.mobileMenuOpen span {
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.mobile-menu span:before {
  -webkit-transition-property: margin, -webkit-transform;
  transition-property: margin, transform, background-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.mobile-menu.mobileMenuOpen span:before,
.mobile-menu.mobileMenuOpen span:after {
  background-color: #fff;
}
.mobile-menu.mobileMenuOpen span:before {
  margin-top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
.mobile-menu span:after {
  -webkit-transition-property: margin, -webkit-transform;
  transition-property: margin, transform, background-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.mobile-menu.mobileMenuOpen span:after {
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
</style>
