<template>
  <div class="tools-container">
    <div class="single" v-if="true">
      <p>
        <a href="https://bit.ly/3vd7Udo" target="_blank">
          <span>Build your websites and portfolio with Editor X</span>
          <br />
          <img src="@/assets/editor-x-type.svg" class="editorx-logo" alt="" />
          <img src="https://bit.ly/35XsALk" class="editorx" alt="" />
        </a>
      </p>
    </div>
    <!-- /.single -->
    <div class="multiple" v-else>
      <p>Build your portfolio now</p>
      <div class="tools">
        <a href="javascript:;">
          <img src="@/assets/semplice-logo.png" alt="" />
          <span>Semplice</span>
        </a>
        <a href="javascript:;">
          <img src="@/assets/squarespace-logo.png" alt="" />
          <span>Squarespace</span>
        </a>
        <a href="javascript:;">
          <img src="@/assets/carbonmade-logo.png" alt="" />
          <span>CarbonMade</span>
        </a>
        <a href="javascript:;">
          <img src="@/assets/webflow-logo.png" alt="" />
          <span>WebFlow</span>
        </a>
      </div>
    </div>
    <!-- /.multiple -->
  </div>
  <!-- /.tools-container -->
</template>

<script>
export default {
  name: "Tools",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.tools-container {
  display: block;
  background: $bg-gray;
  width: 100%;
  float: left;
  padding: 18px 20px;
  box-sizing: border-box;
  margin-bottom: 45px;
  margin-top: 5px;
  font-size: 13px;
  //   background-image: linear-gradient(129deg, #000000 0%, #8d8d8d 100%);
  background: $bg-gray;
  border-radius: 6px;
  color: #000;
  @include f_medium();
  p {
    width: 100%;
    margin-bottom: 15px;
    line-height: 24px;
  }
  .single p {
    margin-bottom: 0px;
    width: 100%;
    font-size: 12px;
    .editorx-logo {
      width: 200px;
      clear: both;
      margin: 0 auto;
      display: inline-block;
      margin-top: 20px;
    }
    .editorx {
      width: 1px;
      height: 1px;
      border: 0;
    }
    a {
      float: left;
      clear: both;
      width: 100%;
      line-height: 30px;
      text-align: center;
      margin-left: 20px;
      display: inline-block;
    }
  }
  .tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      width: 105px;
      float: left;
      @include click();
      font-size: 12px;
      img {
        width: 100%;
        height: auto;
        display: inline-block;
        float: left;
        border-radius: 6px;
      }
      span {
        display: inline-block;
        width: 175px;
        float: left;
        margin-top: 13px;
        color: $blue;
      }
      &:hover span {
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .tools-container {
    display: block;
    .tools {
      display: block;
      float: left;
    }
    .single {
      p a {
        width: calc(100% - 40px);
        float: right;
        margin: 0px;
        margin-left: 10px;
        height: auto;
      }
    }
    a {
      width: 100%;
      margin-bottom: 10px;
      img {
        width: 35px;
      }
      span {
        width: 150px;
        text-indent: 10px;
      }
    }
  }
}
</style>
