<template>
  <div class="coming-soon-container">
    <div class="content">
      <div class="loading">
        <div class="loading-banner"></div>
        <div class="loading-banner loading-banner-2"></div>
        <div class="loading-banner loading-banner-3"></div>
      </div>
      <div class="top">
        <a class="logo" href="javascript:;" @click="play()">
          <img src="@/assets/pfolios-net-logo-type.svg" alt="" />
        </a>
        <a
          class="swag"
          :class="{ animate: swagAnimation }"
          href="javascript:;"
          @click="play()"
        >
          <img src="@/assets/swag.svg" alt="" />
        </a>
      </div>
      <!-- /.top -->
      <div class="subscribe">
        <p>
          Subscribe below to get 3 beautiful portfolios into your inbox every
          week and
          <span>get notified when we launch new products</span>.
        </p>
        <input
          type="email"
          autofocus
          placeholder="Enter your email"
          v-model.trim="email"
          @keyup.enter="submit"
          :class="{ wobble: isWobbling }"
        />
        <a href="javascript:;" @click="submit" class="submit-button">Chip in</a>
        <div class="status" v-show="status.trim() != ''">
          <vue-typer
            caret-animation="blink"
            :text="status"
            :repeat="1"
            :type-delay="30"
            :pre-erase-delay="20000000"
          ></vue-typer>
        </div>
      </div>
      <!-- /.subscribe -->
      <h1>
        <br /><br /><br />
        <router-link to="Home" v-if="!app.isMobile" href="javascript:;"
          >Go back</router-link
        >
        <a href="javascript:;" v-else @click="app.mobileMenuOpen = false"
          >Go back</a
        >
      </h1>
    </div>
    <!-- /.content -->
  </div>
  <!-- /.coming-soon-container -->
</template>

<script>
import { VueTyper } from "vue-typer";
import request from "request-promise";

export default {
  name: "Subscribe",
  components: { VueTyper },
  store: ["app"],
  data() {
    return {
      isWobbling: false,
      email: "",
      status: " ",
      success: true,
      swagAnimation: true
    };
  },
  methods: {
    play: function() {
      window.swagSound.play();
      this.swagAnimation = false;
      setTimeout(() => {
        this.swagAnimation = true;
      }, 150);
    },
    submit: function() {
      this.status = "☝️ Just a second ...";

      if (this.email === "") {
        this.status = "⛔️ Please enter a valid email address.";
        return;
      }
      //Post
      request
        .post({
          uri: "https://empweekly.com/sendy/subscribe",
          form: {
            api_key: "aT3dOMi2pRtyya5VJUkK",
            email: this.email,
            list: "EQ1tRO9pEKbX2IA98cEDSw",
            boolean: "true"
          }
        })
        .then(res => {
          setTimeout(() => {
            if (res === "1") {
              this.status = "✅ Success, see you soon.";
              setTimeout(() => {
                this.success = true;
              }, 1500);
              return;
            }

            this.status = "⛔️  " + res;
          }, 1500);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/inc/common.scss";

.coming-soon-container {
  background: url("~@/assets/blurry-bg.png") #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  position: fixed;
  box-sizing: border-box;
  overflow-y: scroll;
  left: 0px;
  top: 0px;
  color: #000;
  z-index: 5000;
  .content {
    display: block;
    padding: 100px 60px;
    margin-bottom: 60px;
    float: left;
    overflow-x: hidden;
    .loading {
      height: 50px;
      border-bottom: 1px solid #000;
      background: #34e713;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100vw;
      z-index: 2000;
      overflow-x: hidden;
      $width: 736px * 3;
      .loading-banner {
        width: $width;
        background: url("~@/assets/subscribe-for-the-best.svg");
        transform: translateZ(0);
        background-repeat: repeat;
        height: 26px;
        margin-top: 9px;
        animation: slide-left 35s linear;
        animation-iteration-count: infinite;
        left: 0px;
        top: 0px;
        position: absolute;
      }
      .loading-banner-2 {
        left: $width;
        //   background: red;
      }
      .loading-banner-3 {
        left: -1 * $width;
        //   background: red;
      }
    }
    a:hover {
      color: $blue;
    }
    .top {
      float: left;
      clear: both;
      margin-bottom: 50px;
      z-index: 100;
      .logo {
        float: left;
        @include click();
      }
      .swag {
        float: left;
        margin-left: 22px;
        margin-top: 1px;
        position: relative;
        @include click();
        &.animate {
          animation: dealwit 2s forwards;
        }
      }
    }
    h1,
    .subscribe p {
      max-width: 530px;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      float: left;
      clear: both;
      span {
        @include f_italic();
        text-shadow: 1px 1px greenyellow;
      }
      a {
        display: inline-block;
        @include click();
        position: relative;
        //   overflow: hidden;
        text-decoration: none;
        color: $blue;
        &::after {
          content: "";
          background: rgba(greenyellow, 0.25);
          position: absolute;
          left: 6px;
          bottom: -1px;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          z-index: -1;
          transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
        }
        &:hover:after {
          background: rgba(greenyellow, 1);
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 100%;
        }
      }
    }
    h1 {
      margin-bottom: 50px;
      max-width: 680px;
    }
    .coming-online {
      float: left;
      clear: both;
      font-size: 36px;
      @include f_italic();
      margin-bottom: 50px;
      span {
        @include f_medium();
        font-style: normal;
      }
    }
    .subscribe {
      p {
        font-size: 14px;
        line-height: 180%;
      }
      input {
        float: left;
        clear: both;
        width: 100%;
        max-width: 340px;
        border: 1px solid $blue;
        @include f_regular();
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        text-indent: 14px;
        margin-top: 12px;
        &.wobble {
          animation-name: wobble;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          transform-origin: 50% 100%;
        }
      }
      .submit-button {
        padding: 14px 28px;
        background: $blue;
        color: #fff;
        @include f_medium();
        display: inline-block;
        float: left;
        font-size: 14px;
        border-radius: 6px;
        clear: both;
        margin-top: 15px;
        @include click();
      }
      .status {
        float: left;
        padding: 30px 0px;
        font-size: 14px;
        margin-left: 20px;
      }
    }
    .rabbit {
      font-size: 14px;
      line-height: 180%;
      @include f_italic();
      margin-top: 50px;
      float: left;
      clear: both;
      position: fixed;
      right: 10px;
      bottom: 10px;
      a {
        display: inline-block;
        @include click();
        position: relative;
        //   overflow: hidden;
        text-decoration: none;
        color: $blue;
        &::after {
          content: "";
          background: rgba(greenyellow, 0.25);
          position: absolute;
          left: 6px;
          bottom: -1px;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          z-index: -1;
          transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
        }
        &:hover:after {
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 100%;
          background: rgba(greenyellow, 1);
        }
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .coming-soon-container {
    .content {
      display: block;
      padding: 60px 30px;
      margin-bottom: 20px;
      .loading {
        transform-origin: left top;
        transform: scale(0.5);
        width: 200vw;
      }
      .top {
        margin-bottom: 30px;
        .logo img {
          width: 200px;
        }
        .swag img {
          width: 60px;
        }
        .swag {
          margin-left: 6px;
        }
      }
      h1,
      .subscribe p {
        font-size: 14px;
        line-height: 150%;
      }
      h1 {
        margin-bottom: 30px;
        p {
          margin-top: 20px;
        }
      }
      .coming-online {
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 20px;
        span {
          clear: both;
          display: block;
          float: left;
          width: 100%;
        }
      }
      .subscribe {
        .status {
          margin-left: 0px;
          width: 100%;
          padding: 20px 0px;
        }
      }
      .rabbit {
        position: inherit;
      }
    }
  }
}

@keyframes slide-left {
  from {
    transform: translateX(50%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes dealwit {
  from {
    top: -250px;
  }
  to {
    top: 0px;
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(2%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(2%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(1%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(1%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}
</style>
