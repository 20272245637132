<template>
  <div class="blue-bottom-container">
    <img class="section-header" src="@/assets/more-tools.svg" alt="" />
    <div class="section-content more-tools">
      <a href="https://flatuicolors.com" target="_blank">
        <span>🎨</span> Flat UI Color Palettes
      </a>
      <a href="https://thestocks.im" target="_blank">
        <span>📸</span> Free Stock Photos
      </a>
      <a href="https://usepanda.com" target="_blank">
        <span>🐼</span> News Reader for Tech People
      </a>
      <a href="https://collectui.com" target="_blank">
        <span>🖥</span> UI Design Inspiration
      </a>
      <a href="https://thenews.im" target="_blank">
        <span>📱</span> Mobile News Reader for Tech People
      </a>
    </div>
    <img class="section-header" src="@/assets/network-partners.svg" alt="" />
    <div class="section-content network-partners">
      <img src="@/assets/partner-images.png" alt="" />
      <p>
        Asteya Network is reaching more than 900.000 unique creatives monthly.
        <a href="https://asteya.network/contact" target="_blank"
          >Become our partner now</a
        >
        and reach the best designers, developers, entrepreneurs and other people
        in the tech industry with the <span>best rates</span>.
      </p>
    </div>
  </div>
  <!-- /.blue-bottom-container -->
</template>

<script>
export default {
  name: "BlueBottom",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.blue-bottom-container {
  display: block;
  padding: 0px;
  margin-top: 30px;
  width: 100%;
  float: left;
  color: #000;
  .section-header {
    float: left;
    clear: both;
    display: inline-block;
    margin-top: 30px;
  }
  .section-content {
    margin-top: 16px;
    font-size: 12px;
    float: left;
    margin-bottom: 20px;
    @include f_medium();
  }
  .more-tools {
    a {
      display: inline-block;
      width: 100%;
      clear: both;
      float: left;
      font-size: 12px;
      line-height: 30px;
      @include f_italic();
      span {
        @include f_medium();
        font-style: normal;
        font-size: 16px;
      }
      &:hover {
        text-decoration: underline;
        &:after {
          opacity: 1;
        }
      }
      &:after {
        content: " →";
        opacity: 0;
        color: $blue;
      }
    }
  }
  .network-partners {
    img {
      width: 100%;
      display: inline-block;
      float: left;
      margin-top: 10px;
    }
    p {
      font-size: 11px;
      line-height: 22px;
      margin-top: 30px;
      float: left;
      width: 100%;
      @include f_regular();
      opacity: 0;
      a {
        font-weight: bold;
        text-decoration: underline;
        font-style: italic;
        word-wrap: break-word;
        text-decoration: underline !important;
        @include a_folios();
      }
    }
    &:hover p {
      opacity: 1;
    }
  }
  span {
    font-weight: bold;
    font-style: italic;
  }
}
@media (max-width: $mobile-max) {
  .blue-bottom-container {
    display: block;
  }
}
</style>
