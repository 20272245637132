<template>
  <div class="submit-container">
    <h2>Submit</h2>
    <div class="before-submit" v-if="!submitClicked">
      <p>
        Submit the beautiful portfolios you find on the internet.
      </p>
      <input
        type="url"
        value="http://"
        autofocus
        placeholder="Enter portfolio URL"
        v-model="submitUrl"
      />
      <a
        :href="
          'https://docs.google.com/forms/d/e/1FAIpQLSdw15GaQ7u-hyUfTCnPrdUAeQPN6dmDtMRPs_SqnajdhOvd8Q/viewform?usp=pp_url&entry.1309331376=' +
            submitUrl
        "
        target="_blank"
        class="button"
        @click="submitClicked = true"
        >Submit</a
      >
    </div>
    <!-- /.before-submit -->
    <div class="after-submit" v-else>
      <p>
        Thank you for your submission. Leave the rest with us. Peace ✌️
      </p>
    </div>
    <!-- /.after-submit -->
  </div>
  <!-- /.submit-container -->
</template>

<script>
export default {
  name: "Submit",
  components: {},
  data() {
    return {
      submitUrl: "",
      submitClicked: false
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.submit-container {
  display: block;
  width: calc(100% - 10px);
  h2 {
    margin-bottom: 20px;
    margin-top: $height-page-margin-top + 14px;
  }
  p {
    float: left;
    @include p();
    margin-top: 10px;
    margin-bottom: 5px;
    a {
      @include a_folios();
      text-decoration: underline;
    }
  }
  input {
    height: 30px;
    border: none;
    text-indent: 10px;
    @include f_regular();
    font-size: 10px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    height: 34px;
    line-height: 34px;
  }
  .button {
    @include button();
    font-size: 10px;
    margin-top: 20px;
  }
}
@media (max-width: $mobile-max) {
  .submit-container {
    display: block;
  }
}
</style>
