<template>
  <div class="footer-container">
    <p>Follow <span>PFolios</span> for the latest portfolios:</p>
    <div class="links">
      <a href="https://twitter.com/p_folios">Twitter</a>
      <a href="https://www.instagram.com/pfolios/">Instagram</a>
      <router-link to="/subscribe" href="javascript:;"
        >Weekly Newsletter</router-link
      >
    </div>
    <div class="second">
      <p>
        Reach us
        <span>
          <a
            href="mailto:hello@usepanda.com?subject=Pfolios Feedback"
            target="_blank"
            >via email</a
          ></span
        >
        to share your feedback.
      </p>
    </div>
  </div>
  <!-- /.footer-container -->
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.footer-container {
  display: block;
  margin-bottom: 60px;
  font-size: 12px;
  width: 100%;
  float: left;
  margin-top: 45px;
  box-sizing: border-box;
  p {
    font-style: italic;
    float: left;
    span {
      text-shadow: 1px 1px greenyellow;
    }
  }
  .links {
    float: left;
  }
  a {
    display: inline-block;
    margin-left: 33px;
    text-decoration: underline !important;
    @include a_folios();
  }
  .second {
    p {
      width: 100%;
      margin-top: 33px;
      text-align: left;
    }
    a {
      margin: 0px;
    }
  }
}
@media (max-width: $mobile-max) {
  .footer-container {
    display: block;
    padding: 0px 15px;
    text-align: center;
    p {
      width: 100%;
    }
    .links {
      width: 100%;
      display: inline-block;
      a {
        margin: 30px 10px;
      }
    }
  }
}
</style>
