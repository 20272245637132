var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.app.selectedItem)?_c('div',{staticClass:"single-container"},[_c('h2',[_vm._v(_vm._s(_vm.app.selectedItem.name))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.app.selectedItem.title))]),_c('div',{staticClass:"buttons"},[_c('a',{class:_vm.device == 'web' ? 'selected' : '',attrs:{"href":"javascript:;"},on:{"mouseenter":function($event){_vm.device = 'web';
          _vm.scrollTop();}}},[_vm._v(" Desktop ")]),_c('a',{class:_vm.device == 'mobile' ? 'selected' : '',attrs:{"href":"javascript:;"},on:{"mouseenter":function($event){_vm.device = 'mobile';
          _vm.scrollTop();}}},[_vm._v("Mobile")])]),_c('PerfectScrollbar',{ref:"psPreview",staticClass:"preview",attrs:{"options":{ wheelPropagation: false }}},[_c('vue-load-image',[_c('div',{style:(`background-image: url(${_vm.imgSrc})`),attrs:{"slot":"image","data-src":_vm.imgSrc},slot:"image"}),_c('div',{staticClass:"preloader",attrs:{"slot":"preloader"},slot:"preloader"},[_c('img',{attrs:{"src":require("@/assets/loading.gif")}})]),_c('div',{attrs:{"slot":"error"},slot:"error"},[_vm._v("error message")])]),_c('img',{attrs:{"src":_vm.imgSrc,"alt":""}})],1),_c('a',{staticClass:"url",attrs:{"href":_vm.app.selectedItem.url + '?ref=pfolios.net&💞Love-is-in-the-air...💞',"target":"_blank"}},[_vm._v("https://"+_vm._s(_vm.app.selectedItem.slug)+" "),_c('span',[_vm._v("→")])]),_c('p'),_c('div',{staticClass:"details"},[_c('div',{staticClass:"detail"},[_c('span',{staticClass:"label"},[_vm._v("Built with: ")]),_vm._v(" "),_c('i',[_vm._v(_vm._s(_vm.builthWithName))])]),_c('div',{staticClass:"detail"},[_c('span',{staticClass:"label"},[_vm._v("Styles: ")]),_vm._l((_vm.itemStyles),function(style,index){return _c('i',{key:style.id},[_vm._v(" "+_vm._s(style.name)),(index != _vm.itemStyles.length - 1)?_c('span',[_vm._v(",")]):_c('span',[_vm._v(".")])])})],2),(
          _vm.app.selectedItem.twitter ||
            _vm.app.selectedItem.instagram ||
            _vm.app.selectedItem.linkedin
        )?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"label"},[_vm._v("Follow on: ")]),(_vm.app.selectedItem.twitter)?_c('a',{attrs:{"href":'https://twitter.com/' + _vm.app.selectedItem.twitter,"target":"_blank"}},[_vm._v("Twitter")]):_vm._e(),(_vm.app.selectedItem.linkedin || _vm.app.selectedItem.instagram)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.app.selectedItem.instagram)?_c('a',{attrs:{"href":'https://instagram.com/' + _vm.app.selectedItem.instagram,"target":"_blank"}},[_vm._v("Instagram")]):_vm._e(),(_vm.app.selectedItem.linkedin)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.app.selectedItem.linkedin)?_c('a',{attrs:{"href":'https://linkedin.com/in/' + _vm.app.selectedItem.linkedin,"target":"_blank"}},[_vm._v("LinkedIn")]):_vm._e()]):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }